<div class="container">
    <div class="draw-result-container" *ngIf=" draw && draw.title">
        <div class="row">
            <div class="col-md-6 py-3">
                <h4>View Draw Results</h4>
                <h3>{{draw.title}}</h3>
                <!-- Date -->
                <p class="clr-sec-text-f">{{"poll_chart_published"|translate}} {{ draw.updated | momentPipe: "DD/MM/YY HH:mm"}}
                </p>
            </div>
            <div class="col-md-6 py-3 text-end">
                <button mat-raised-button color="primary" (click)="goToCreateDraw()">
                    <i class="fas fa-plus me-2 align-baseline"></i>
                    Create New Draw</button>
                <div class="pt-2">
                    <button mat-raised-button color="primary" (click)="goToDraws()">
                        <i class="fas fa-gift me-2 align-baseline"></i>
                        View your Draw's </button>
                </div>
            </div>
        </div>
        <!-- Download Contestants -->
        <div class="row">
            <div class="pb-2 ">
                <button mat-raised-button color="primary" (click)="downloadContestants(draw)">
                    <i class="fas fa-users px-2 align-baseline"></i>
                    Download Contestants</button>
            </div>
            <div class="col-sm-12">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="prize">
                        <th mat-header-cell *matHeaderCellDef>Prize</th>
                        <td mat-cell *matCellDef="let prize">
                            {{ prize.prizeValue }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="winner">
                        <th mat-header-cell *matHeaderCellDef>Winner</th>
                        <td mat-cell *matCellDef="let prize">
                            <span class="badge  text-bg-success text-wrap fs-6">
                                {{ prize.winner }}
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="reserves">
                        <th mat-header-cell *matHeaderCellDef>Reserves</th>
                        <td mat-cell *matCellDef="let prize">
                            <div class="runner-ups-container">
                                <div class="m-auto" *ngIf="prize.runnerUps.length==0; else reservers">
                                    There are no Reserves
                                </div>
                                <ng-template #reservers>
                                    <div *ngFor="let runnerUp of prize.runnerUps" class="runner-up">
                                        {{ runnerUp }}
                                    </div>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                </table>
            </div>
        </div>
    </div>
</div>