<div class="container-fluid sub-top-bar">
    <div class="row">
        <div class="col-sm-12">
            <!-- Search Input -->
            <mat-form-field class="search-field" appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput (keyup.enter)="searchDraws($event)" placeholder="Search draws" #input>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row draw-table-container">
        <div class="col-md-6 py-3">
            <h4>Draws</h4>
            <p *ngIf="dataSource?.data?.length"> Total draws: {{dataSource.data.length}}</p>
        </div>
        <!-- Add draw -->
        <div class="col-md-6 py-3 text-end">
            <button mat-raised-button color="primary" (click)="onCreateDraw()">
                <i class="fas fa-solid fa-plus me-2 align-baseline "></i> Create draw
            </button>
        </div>
        <div class="col-md-12">
            <table class="w-100" mat-table [dataSource]="dataSource">
                <!-- Title -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>Title</th>
                    <td mat-cell *matCellDef="let draw">{{draw.title}}</td>
                </ng-container>
                <!-- Contestants -->
                <ng-container matColumnDef="contestants">
                    <th mat-header-cell *matHeaderCellDef>Contestants</th>
                    <td mat-cell *matCellDef="let draw" (click)="downloadContestants(draw)" class="px-3">
                        <i class="fas fa-users fa-lg icon" matTooltip="Download contestants"></i>
                    </td>
                </ng-container>
                <!-- winners -->
                <ng-container matColumnDef="winners">
                    <th mat-header-cell *matHeaderCellDef>Total winners</th>
                    <td mat-cell *matCellDef="let draw" class="px-3">
                        <span class="badge winners">
                            {{draw.prizes.length}}
                        </span>
                    </td>
                </ng-container>
                <!-- Actions -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let draw">
                        <i class="fas fa-eye fa-lg mx-2 icon" matTooltip="View winners" (click)="onGetDraw(draw)"></i>
                    </td>
                </ng-container>
                <!-- Select -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox class="custom-frame" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox class="custom-frame" (change)="$event ? selection.toggle(element): null" (click)="$event.stopPropagation()" [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <!-- Delete -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="w-25">
                        <button *ngIf="isSomeSelected()" [@fadeInOut] mat-flat-button color="warn" (click)="onDelete()">
                            <i class="far fa-trash-alt"> </i>
                            <span class="ps-1">
                                {{ "gen_delete"|translate}}
                            </span>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let draw"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No draws was found</td>
                </tr>
            </table>
        </div>
    </div>
</div>