<div class="container">
    <div class="create-draw-container">
        <div class="row">
            <div class="col-sm-12">
                <h4> Create draw
                </h4>
                <p>Complete the below fields to create your draw.</p>
            </div>
        </div>
        <!-- Stepper -->
        <div class="container">
            <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
                <!-- Add Title -->
                <mat-step [stepControl]="firstFormGroup">
                    <div class="row">
                        <div class="p-3 col-sm-12">
                            <form [formGroup]="firstFormGroup">
                                <ng-template matStepLabel>Enter title</ng-template>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Draw title</mat-label>
                                    <input matInput type="text" formControlName="title" placeholder="Enter draw title" required>
                                    <mat-error>Title is required</mat-error>
                                </mat-form-field>
                                <div class="text-end actions">
                                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </mat-step>

                <mat-step [stepControl]="secondFormGroup" label="Entries" *ngIf="!automaticEntries">
                    <div class="p-3">
                        <h5>Please upload your entries file.</h5>
                        <p class="pt-2">Each line / row in your file is one entry. Empty / blank lines are excluded.</p>
                        <p>We recommend removing sensitive data from your uploaded file like email addresses, home addresses, and phone numbers.</p>
                    </div>
                    <form [formGroup]="secondFormGroup">
                        <div class="custom-file">
                            <input id="upload" class="custom-file-input" type="file" formControlName="file" accept=".csv" (change)="onChangeFile($event)">
                            <label class="custom-file-label" for="upload"><i
                                    class="fas fa-cloud-upload-alt align-baseline"></i>Choose
                                file</label>

                            <div *ngIf="!showErrorMessage && fileControl?.value">
                                <div class="alert alert-success mt-3 success-alert" role="alert">
                                    <p> <i class="fas fa-check me-2"></i> {{ filename}}</p>
                                </div>
                                <div class="mt-3 row">
                                    <div class="d-flex">
                                        <h4>Is this a header row or entry row? </h4>
                                        <div class="ms-auto">

                                            <button mat-raised-button color="primary" (click)="selectAllCols()">
                                                {{selectAllColumns ? 'Select specific column ' : 'Select all columns'}}</button>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="d-flex" style="overflow-x: auto;">
                                            <div class="pt-2 firstLine" *ngFor="let item of firstLine let i = index">
                                                <li (click)="selectIndex(i)" [ngClass]="{selected: selectAllColumns || i === selectedColumn}">
                                                    {{item}}</li>
                                            </div>
                                        </div>
                                        <div class="pt-4">
                                            <mat-radio-group class="d-flex flex-column" formControlName="headerConfirm">
                                                <mat-radio-button class="pb-2" [value]="true">This is a header row.
                                                </mat-radio-button>
                                                <mat-radio-button [value]="false">This is an entry row.
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div *ngIf="showErrorMessage" class="alert alert-danger mt-3" role="alert">
                            Please upload a valid CSV file.
                        </div>

                        <!-- File tips -->
                        <div *ngIf="!fileControl?.value || showErrorMessage" class="alert alert-light myinfo mt-3" role="alert">
                            <h5 class="alert-heading">
                                <i class="fas fa-exclamation-triangle"></i> Warning!
                            </h5>
                            <p>txt, csv, or tsv formats only, maximum 100MB. Do not zip or password protect the file. Please remove any blank rows.</p>
                            <p>If you would like to use an Excel file, please save it as a CSV before uploading.</p>
                            <p>To do so, follow these steps:</p>
                            <ol>
                                <li>
                                    Open the Excel file, and go to File > Save As
                                </li>
                                <li>
                                    When selecting the filename and location, click the "Save as Type" dropdown box and select "CSV (Comma delimited) (*.csv)
                                </li>
                                <li>
                                    Save the file. If you receive any prompts warning you about some features being missing, continue through them
                                </li>
                                <li>
                                    Upload the new CSV file you just saved here
                                </li>
                            </ol>
                            <p class="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
                        </div>
                        <div class="text-end actions">
                            <button mat-raised-button color="primary" class="me-2" matStepperPrevious>Back</button>
                            <button mat-raised-button color="primary" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>




                <!-- Add prizes -->
                <mat-step label="Add prizes" [stepControl]="thirdFormGroup">
                    <div class="p-3">
                        <form [formGroup]="thirdFormGroup">
                            <div class="row">
                                <h5 class="col-md-12">Prizes</h5>
                            </div>
                            <div formArrayName="prizes">
                                <div class="form-container">
                                    <div *ngFor="let prize of prizes.controls; let i=index">
                                        <div [formGroupName]="i">
                                            <div class="row">
                                                <mat-form-field appearance="fill" class="col-sm-8">
                                                    <mat-label>Name</mat-label>
                                                    <input matInput type="text" formControlName="name">
                                                    <mat-error>Name is required</mat-error>
                                                </mat-form-field>
                                                <mat-form-field appearance="fill" class="col-sm-2">
                                                    <mat-label>No of Winners</mat-label>
                                                    <input matInput type="number" min="1" formControlName="winners">
                                                </mat-form-field>
                                                <div class="col-sm-2 m-auto">
                                                    <i class="fas fa-trash fa-lg" matTooltip="Delete prize" (click)="deletePrize(i)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex">
                                    <mat-form-field appearance="fill" class="w-25 pt-3 col-sm-12">
                                        <mat-label>No of Reserves</mat-label>
                                        <input matInput type=" number " min="0" formControlName="reserves">
                                    </mat-form-field>
                                    <div class="ms-auto mt-auto">
                                        <button mat-mini-fab (click)="addPrize()" type="button" matTooltip="Add Prize">
                                            <i class="fas fa-plus fa-lg"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="pt-3 ">
                                    <p class="fw-bold ">Total winners: {{totalWinners}}</p>
                                </div>
                            </div>
                            <div class="text-end actions">
                                <button mat-raised-button color="primary" class="me-2" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" matStepperNext>Next</button>
                            </div>
                        </form>
                    </div>
                </mat-step>


                <mat-step label="Publish Draw">
                    <div class="publish">
                        <div class="p-3">
                            <div class="alert alert-light myinfo mt-3" role="alert">

                                <p>Congratulations! You're one step away from creating a brand new draw that is sure to bring excitement and fun to your audience.</p>
                                <p>You have taken the time to carefully upload prizes and reserves, your draw is shaping up to be a valuable and attractive event for everyone involved.</p>
                                <p>However, before you proceed, it is important to double-check all the information you've provided. Once the draw is created, it cannot be edited or deleted, so it's important to make sure everything is correct.</p>
                                <p class="mb-0">So take a moment to review your work, and when you're ready, click the 'Create Draw' button to launch the event and bring a touch of excitement to your community. Good luck!</p>
                            </div>
                        </div>
                        <button class='create-draw' mat-raised-button color="primary" (click)="createDraw() ">Create
                            Draw</button>
                        <div class="text-end actions">
                            <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>