<div class="card">
    <div class="card-header h-auto">
        {{"poll_chart_live_feed"|translate}}
    </div>
    <app-csv [data]="config" [title]="poll?.title"></app-csv>
    <div class="card-body">
        <div class="pie-chart">
            <h3 class="chart-title">{{config.title}}</h3>
            <apx-chart [chart]="basicConfig.chart" [legend]="basicConfig.legend" [plotOptions]="basicConfig.plotOptions" [series]="config.series" [labels]="config.labels" [theme]="basicConfig.theme"></apx-chart>
        </div>
    </div>
</div>