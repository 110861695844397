import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* App Usefull Providers */
import { AppInitializer } from './app.initializer';
import { AppHttpInterceptor } from './app.http.interceptor';

/* Services */
import { UserService } from './services/userService/user.service';

/* Modules */
import { ShareModule } from './share-module';
import { ComponentsModule } from './components/components-module';

/* So EasyTV Libraries */
import { SidebarModule } from '@andreasmeis/sidebar';
/* TopBar */
import { TopBarModule } from '@andreasmeis/top-bar';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShareModule,
    ComponentsModule,
    SidebarModule,
    TopBarModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      multi: true,
      deps: [UserService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
