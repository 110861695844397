import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.less']
})
export class PublishDialogComponent {
  title: string;
  publish: string;
  constructor(public dialogref: MatDialogRef<PublishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.publish = data.status == 0 ? 'Publish' : 'Republish';
  }

  onConfirm(): void {
    this.dialogref.close(true)
  }

  onDismiss(): void {
    this.dialogref.close(false)
  }

}
