import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from '../environments/environment';
import { ignoreLoader } from './configs/ignore.loader';


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  loading: string[] = new Array();
  constructor() { }
  // function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.showLoader(request);
    this.loading.push("val");

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            this.checkQueue();
          }
        },
        error => {
          this.checkQueue();
        }
      )
    );
  }

  // Show Loader
  showLoader(request: any) {
    if (!this.ignoreLoader(request)) {
      const loader = document.getElementById("loader");
      if (loader) {
        loader.style.display = "inline-block"
      }
    }
  };

  // Hide Loader
  hideLoader() {
    const loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = "none"
    }
  };

  // Ingore Loader
  ignoreLoader(request: any) {
    return ignoreLoader.apis.find(url => {
      return request.url.startsWith(environment.apiUrl + url);
    });
  }

  // Check loading array to see if a request didn't finish yet
  checkQueue() {
    this.loading.pop();
    if (this.loading.length == 0) {
      this.hideLoader();
    }
  };
}