import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from './services/languageService/language.service';
import { UserService } from './services/userService/user.service';
import { TopBar } from '@andreasmeis/top-bar';
import { environment } from 'src/environments/environment';
import { RequestService } from './services/requestService/request.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  isLoggedIn: any = null;

  logoImgPath: string = '/assets/images/logo/soeasytv_black.png';

  user!: TopBar;

  tempMenu = new Array({
    name: "Polls",
    icon: "fa-solid fa-gauge-high",
    link: "/"
  }, {
    name: "Create",
    icon: "fa-solid fa-plus",
    link: "/create-poll"
  },{
      name: "Draws",
      icon: "fa-solid fa-gift",
      link: "/draws"
  });

  constructor(private languageService: LanguageService, private userService: UserService, private router: Router, private request: RequestService) {
    this.languageService.setLanguage("en");
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.removeItem('period')
  }

  ngOnInit() {
    this.checkLoggedInStatus(this.userService.isLoggedIn(), true);
    this.user = {
      user: {
        id: this.userService.getUser().id,
        email: this.userService.getUser().email,
        firstName: this.userService.getUser().firstName,
        lastName: this.userService.getUser().lastName,
        phone: this.userService.getUser().phone,
        role: this.userService.getUser().role,
        solutionEnvironment: this.userService.getUser().solutionEnvironment,
        avatar: this.userService.getUser().avatar
      },
      company: {
        id: this.userService.getCompany().id,
        name: this.userService.getCompany().name
      },
      url: environment.idsUrl

    }
    // Create a listener in order to know about logged or logout state
    this.userService.getLoggedInStatus.subscribe((data: any) => {
      this.checkLoggedInStatus(data, false);
    });
  }

  // Change Mode (Dark - Light)
  changeTheme(theme: string): void {
    this.userService.changeTheme(theme);
    this.logoImgPath = '/assets/images/logo/soeasytv_' + (theme == 'dark' ? 'white' : 'black') + '.png';
  }

  onSwitchCompany() {
    this.request.mainGetRequest({ url: 'Auth/switchCompany' }).then((resp: any) => {
      window.location.href = resp.data.redirectUrl
    })
  }

  

  // It check the loggen status
  private checkLoggedInStatus(data: boolean, isInit: boolean) {
    this.isLoggedIn = data;
    if (data == false) {
      this.userService.resetUser();
      this.userService.resetCompany();
      if (!isInit) {
        this.router.navigate(["/login"]);
      }
    } else {
      if (!isInit) {
        this.router.navigate(["/dashboard"]);
      }
    }
  }
}
