import { Component, Input} from '@angular/core';
import { ChartOptions } from 'src/app/interface/chart-option';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.less']
})
export class PieChartComponent {
  @Input() config: Partial<ChartOptions> | any
  @Input('poll') poll!: any;
  basicConfig: Partial<ChartOptions> | any = {
    chart: {
      height: 500,
      type: "donut",
      toolbar: {
        show: true,
        offsetY: -190,
        export: {
          svg: {
            filename: this.poll?.title,
          },
          png: {
            filename: this.poll?.title,
          }
        }
      },
      animations: {
        enabled: false,
        easing: 'easein',
        speed: 300
      }
    },
    legend: {
      position: "bottom"
    },
    theme: environment.theme.pallete,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'Σύνολο',
              formatter: function (w: any) {
                return w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return a + b
                }, 0)
              }
            }
          }
        }
      }
    }
  };

  ngOnInit(): void {
    this.basicConfig.chart.toolbar.export['svg'].filename = this.poll?.title;
    this.basicConfig.chart.toolbar.export['png'].filename = this.poll?.title;
  }
}
