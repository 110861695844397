import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fadeInOut } from 'src/app/animations/animations';
import { Draw, DrawData } from 'src/app/interface/draw';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { DrawService } from 'src/app/services/drawService/draw.service';

@Component({
  selector: 'app-draws',
  templateUrl: './draws.component.html',
  styleUrls: ['./draws.component.less'],
  animations: [fadeInOut]
})
export class DrawsComponent implements OnInit {
  searchValue: string = ''
  dataSource: any
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selection = new SelectionModel<any>(true, [])
  deleteArray: Array<Number> = []
  displayedColumns: string[] = ["title", "winners", "contestants", "actions", "select", "delete"]
  constructor(private dialog: MatDialog, private router: Router, private drawService: DrawService) {
    this.onGetDraws()
  }

  ngOnInit(): void { }

  // Create new Draw
  onCreateDraw(): void {
    this.router.navigateByUrl("/create-draw")
  }

  // Open Draw Dialog
  onGetDraw(row: Draw) {
    this.router.navigateByUrl(`draw-result/${row.drawId}`)
  }

  // get all Draws
  onGetDraws() {
    this.drawService.getDraws().then((resp: any) => {
      if (resp.success) {
        this.deleteArray.length = 0
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource = new MatTableDataSource<DrawData>(resp.data)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  /**
  Get draw contestants
   * @param {draw} 
   */
  downloadContestants(draw: Draw) {
    this.drawService.downloadContestants(draw.drawId).then( (resp: any) =>{
      saveAs(resp.blob, `Contestants_${draw.title}_${moment().format('DD/MM/YYYY')}.csv`);
    })
  }


  // Delete Draws
  onDelete() {
    for (let i = 0; i < this.selection.selected.length; i++) {
      this.deleteArray.push(this.selection.selected[i].drawId)
    }

    this.drawService.deleteDraws([... new Set(this.deleteArray)]).then( (resp: any) => {
      this.onGetDraws();
    })

  }
  // search Draws
  searchDraws(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Check if all rows are selected
  isAllSelected() {
    let numSelected = this.selection.selected.length;
    let numRows = this.dataSource.length
    return numSelected === numRows;
  }

  /**
   * @returns The length of the selected array.
   */
  isSomeSelected() {
    return this.selection.selected.length > 0
  }


  /**
  * If some are selected, clear all selections.
  * If all are selected, clear all selections.
  *  If none are selected, select all
  */
  masterToggle() {
    if (this.isSomeSelected()) {
      this.selection.clear()
    }
    else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach((draw: any) =>
          this.selection.select(draw))
    }
  }
}
