import { UserService } from './services/userService/user.service';

export function AppInitializer(userService: UserService) {
    return () => new Promise(resolve => {
        userService.getUserInfo().then(resp => {
            if (resp) {
                userService.checkStatus(resp)
                resolve(true);
            } else {
                userService.resetUser();
                resolve(true);
            }
        })
    });
}