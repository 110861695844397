import { Component, Input } from '@angular/core';
import { ChartOptions } from 'src/app/interface/chart-option';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.less']
})
export class BarChartComponent {

  @Input() config: Partial<ChartOptions> | any
  @Input('poll') poll!: any;
  series = new Array();

  basicConfig: Partial<ChartOptions> | any = {
    series:[{
      name:'',
      data: []
    }],
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        offsetY: -190,
        export: {
          svg: {
            filename: this.poll?.title,
          },
          png: {
            filename: this.poll?.title,
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '45%'
      }
    },
    theme: environment.theme.pallete,
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: []
    }
  };

  ngOnInit(): void {
    this.basicConfig.xaxis['categories'] = this.config.labels;
    this.basicConfig.series[0].data = this.config['series'];
    this.basicConfig.chart.toolbar.export['svg'].filename = this.poll?.title;
    this.basicConfig.chart.toolbar.export['png'].filename = this.poll?.title;
  }
}
