import { Injectable } from '@angular/core';
import { RequestService } from '../requestService/request.service';

@Injectable({
  providedIn: 'root'
})
export class DrawService {

  constructor(private requestService: RequestService) { }

  getDraws(): Promise<{ success: boolean, data: any }>  {
    return new Promise(resolve => {
      this.requestService.mainGetRequest({ url: 'Draws/getDraws' }).then((resp : any) => {
        resolve({success: resp.success, data: resp.data});
      })
    });
  }

  getDraw(drawId: string): Promise<{ success: boolean, data: any }>  {
    return new Promise(resolve => {
      this.requestService.mainGetRequest({ url: `Draws/getDraw?id=${drawId}`}).then((resp : any) => {
        resolve({success: resp.success, data: resp.data});
      })
    });
  } 

  deleteDraws(params: any): Promise<{ success: boolean, data: any }>  {
    let deleteParams: any = {
      url: 'Draws/deleteDraws',
      req: params
    }
    console.log(deleteParams)
    return new Promise(resolve => {
      this.requestService.mainPostRequest(deleteParams).then((resp : any) => {
        console.log(resp);
        resolve({success: resp.success, data: resp.data});
      })
    });
  }

  downloadContestants(drawId: any):Promise<{ blob: any }>  {
    return new Promise(resolve => {
      this.requestService.downloadDatasetTSV(drawId).subscribe((Response: any) => {
        resolve({blob: new Blob([Response], {
          type: 'text/* ',
        })});
      });
    })
  }

  uploadDraw(params:any): Promise<{ success: boolean, data: any }>  {
    return new Promise(resolve => {

      this.requestService.upload({
        url: '/Draws/' + params.api,
        req: params.formData,
        type: 'FormData'
      }).subscribe((resp: any) => {
        // To DO...
      });
      // this.requestService.mainGetRequest({ url: 'Draws/getDraws' }).then((resp : any) => {
      //   resolve({success: resp.success, data: resp.data});
      // })
    });
  }
}
