import { Component, Input, OnInit } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ChartOptions } from 'src/app/interface/chart-option';
import { CsvFile } from 'src/app/interface/poll';

@Component({
  selector: 'app-csv',
  templateUrl: './csv.component.html',
  styleUrls: ['./csv.component.less']
})

export class CsvComponent implements OnInit {
  @Input('data') config: Partial<ChartOptions> | any;
  @Input('title') title!: string;
  data: CsvFile[] = new Array();
  totalValue: number = 0;
  constructor() {
    this.data.push({category: 'category', value:'value', percentage:'percentage'});
   }

  ngOnInit(): void {
     if(this.config && this.config.labels) {
      this.config.labels.forEach((category: string, i: number) => {
        this.totalValue += this.config.series[i];
        this.data.push({
          category: category,
          value: this.config.series[i],
          percentage : ''
        });
      });
      
      this.data.forEach((data: CsvFile, i: number) => {
        if(i>0)
          data.percentage = this.findPercentage(data.value, this.totalValue);
      });
     }
  }
  // export CSV File
  exportCSV(): void {    
    if (this.data && this.title) new ngxCsv(this.data, this.title); // this.getRandomId(8));
  }
  // set a random ID for export file
  getRandomId(length:number): string {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var result = '';
      for ( var i = 0; i < length; i++ ) {
          result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
      return result;
  }
  // return percentage value
  findPercentage(value: number, totalValue: number): any {
    const percentage = parseFloat(((value / totalValue) * 100).toFixed(1));
    return percentage.toString().length == 2 ? percentage.toString() + '.0%' : percentage + '%';
  }
}
