<div class="container-fluid sub-top-bar">
    <div class="row">
        <div class="col-sm-12">
            <h4>{{poll?.title}}</h4>
            <p class="clr-sec-text-f">{{"poll_chart_published"|translate}} {{poll?.updated | momentPipe: "DD/MM/YY HH:mm"}}
            </p>

            <mat-chip-listbox color="primary">
                <mat-chip-option *ngFor="let contentId of poll?.contentIds" selected>{{contentId}}</mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>
</div>

<div class="container-fluid statistics-container">
    <div class="row">
        <div class="col-lg-6">
            <app-pie-chart [config]="config" [poll]="poll" *ngIf="config?.labels?.length">
            </app-pie-chart>
        </div>
        <div class="col-lg-6">
            <app-pie-chart [config]="configTotal" [poll]="poll" *ngIf="config?.labels?.length">
            </app-pie-chart>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-6">
            <app-bar-chart [config]="config" [poll]="poll" *ngIf="config?.labels?.length">
            </app-bar-chart>
        </div>
        <div class="col-lg-6">
            <app-bar-chart [config]="configTotal" [poll]="poll" *ngIf="config?.labels?.length">
            </app-bar-chart>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-6">
            <app-table-stats [config]="config" [poll]="poll" *ngIf="config?.labels?.length"></app-table-stats>
        </div>
        <div class="col-lg-6">
            <app-table-stats [config]="configTotal" [poll]="poll" *ngIf="config?.labels?.length"></app-table-stats>
        </div>
    </div>
</div>