<div mat-dialog-content>

    <h1 mat-dialog-title>Are you sure? </h1>
    <hr>
    <p>
        Would you like to {{publish}} Poll: <span>
      {{title}} !
    </span>
    </p>
</div>
<div mat-dialog-actions >
    <button mat-raised-button mat-dialog-close (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial (click)="onConfirm()">Yes</button>
</div>