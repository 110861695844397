import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Draw, Prize } from 'src/app/interface/draw';
import { saveAs } from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DrawService } from 'src/app/services/drawService/draw.service';


@Component({
  selector: 'app-draw-result',
  templateUrl: './draw-result.component.html',
  styleUrls: ['./draw-result.component.less']
})
export class DrawResultComponent implements OnInit {
  drawId: string
  draw!: Draw
  dataSource!: MatTableDataSource<Prize>;
  displayedColumns: string[] = ['prize', 'winner', 'reserves'];
  constructor(private route: ActivatedRoute, private router: Router, private drawService: DrawService) {
    this.drawId = String(this.route.snapshot.paramMap.get('id'))
  }

  ngOnInit(): void {
    this.onGetDraw(this.drawId)
  }

  /**
Get draw contestants
 * @param {draw} 
 */
  downloadContestants(draw: Draw) {
    this.drawService.downloadContestants(draw.drawId).then((resp: any) => {
      saveAs(resp.blob, `Contestants_${draw.title}_${moment().format('DD/MM/YYYY')}.csv`);
    });
  }

  onGetDraw(drawId: string) {
    this.drawService.getDraw(drawId).then((resp: any) => {
      this.draw = resp.data
      this.dataSource = new MatTableDataSource<any>(resp.data.prizes)
    });
  }

  goToCreateDraw(): void {
    this.router.navigateByUrl("/create-draw")
  }

  goToDraws(): void {
    this.router.navigateByUrl("/draws")
  }
}
