<!-- Logged In -->
<div class="wrapper">
    <!-- TopBar -->
    <div class="top-bar">
        <soeasytv-top-bar *ngIf="isLoggedIn == true" (switchCompany)="onSwitchCompany()"  (themeChanged)="changeTheme($event)" [data]="user"></soeasytv-top-bar>
    </div>
    <!-- Sidebar -->
    <soeasytv-sidebar class="sidebar" *ngIf="isLoggedIn == true" [sidebarList]="tempMenu"  [logoSrc]="logoImgPath"></soeasytv-sidebar>

    <!-- Content Wrapper -->
    <div class="content-wrapper">
        <!-- Main Content -->
        <div class="content">
            <!-- Routed views go here -->
            <router-outlet class="m-0 p-0"></router-outlet>
        </div>
    </div>
</div>

<!-- Loader -->
<div id="loader" class="loader-main"></div>