import { NgModule } from '@angular/core';
import { ShareModule } from 'src/app/share-module';
import { PollsComponent } from './polls/polls.component';
import { CreatePollComponent } from './create-poll/create-poll.component';
import { PublishDialogComponent } from './publish-dialog/publish-dialog.component';
import { PieChartComponent } from './poll-charts/pie-chart/pie-chart.component';
import { PollChartsComponent } from './poll-charts/poll-charts.component';
import { DrawsComponent } from './draws/draws.component';
import { CreateDrawComponent } from './create-draw/create-draw.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DrawResultComponent } from './draw-result/draw-result.component';
import { BarChartComponent } from './poll-charts/bar-chart/bar-chart.component';
import { CsvComponent } from './csv/csv.component';
import { TableStatsComponent } from './poll-charts/table-stats/table-stats.component';
import { PeriodPickerModule } from '@andreasmeis/period-picker';

const componentsComponents = [
  PollsComponent,
  CreatePollComponent,
  PublishDialogComponent,
  PieChartComponent,
  PollChartsComponent,
  DrawsComponent,
  CreateDrawComponent,
  ErrorDialogComponent,
  DrawResultComponent,
  BarChartComponent,
  CsvComponent,
  TableStatsComponent,
];

@NgModule({
  imports: [
    ShareModule,
    PeriodPickerModule
  ],
  declarations: [
    ...componentsComponents
  ],
  exports: [
    ...componentsComponents
  ]
})

export class ComponentsModule { }
