import { NgModule } from '@angular/core';
import { AngularMaterialModule } from 'src/app/angular-material.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { HttpClient } from '@angular/common/http';

/* Pipes */
import { MomentPipe } from 'src/app/pipes/moment/moment.pipe';

// ApexCharts
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
  imports: [
    AngularMaterialModule,
    NgApexchartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    MomentPipe
  ],
  exports: [
    NgApexchartsModule,
    AngularMaterialModule,
    TranslateModule,
    MomentPipe
  ]
})

export class ShareModule { }

/** Load the proper translation files */
export function HttpLoaderFactory(_httpBackend: HttpClient) {
  return new MultiTranslateHttpLoader(_httpBackend, [
    { prefix: "./assets/i18n/", suffix: ".json" }
  ]);
}

