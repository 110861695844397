<div class="card">
    <div class="card-header h-auto">
        {{"poll_chart_live_feed"|translate}}
    </div>
    <app-csv [data]="config" [title]="poll?.title"></app-csv>
    <div class="card-body">
        <div class="bar-chart">
            <h3 class="chart-title">{{config.title}}</h3>
            <apx-chart [chart]="basicConfig.chart" [plotOptions]="basicConfig.plotOptions" [theme]="basicConfig.theme" [dataLabels]="basicConfig.dataLabels" [series]="basicConfig.series" [xaxis]="basicConfig.xaxis"></apx-chart>
        </div>
    </div>
</div>