export interface CountryCode {
    iso639_1: string,
    iso639_2: string,
    family: string,
    name: string,
    nativeName: string,
};

export const CountryCodes: Array<CountryCode> = [
    {
        "iso639_1": "aa",
        "iso639_2": "aar",
        "family": "Afro-Asiatic",
        "name": "Afar",
        "nativeName": "Afaraf"
    }, {
        "iso639_1": "ab",
        "iso639_2": "abk",
        "family": "Northwest Caucasian",
        "name": "Abkhaz",
        "nativeName": "аҧсуа бызшәа, аҧсшәа"
    }, {
        "iso639_1": "ae",
        "iso639_2": "ave",
        "family": "Indo-European",
        "name": "Avestan",
        "nativeName": "avesta"
    }, {
        "iso639_1": "af",
        "iso639_2": "afr",
        "family": "Indo-European",
        "name": "Afrikaans",
        "nativeName": "Afrikaans"
    }, {
        "iso639_1": "ak",
        "iso639_2": "aka",
        "family": "Niger–Congo",
        "name": "Akan",
        "nativeName": "Akan"
    }, {
        "iso639_1": "am",
        "iso639_2": "amh",
        "family": "Afro-Asiatic",
        "name": "Amharic",
        "nativeName": "አማርኛ"
    }, {
        "iso639_1": "an",
        "iso639_2": "arg",
        "family": "Indo-European",
        "name": "Aragonese",
        "nativeName": "aragonés"
    }, {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "family": "Afro-Asiatic",
        "name": "Arabic",
        "nativeName": "العربية"
    }, {
        "iso639_1": "as",
        "iso639_2": "asm",
        "family": "Indo-European",
        "name": "Assamese",
        "nativeName": "অসমীয়া"
    }, {
        "iso639_1": "av",
        "iso639_2": "ava",
        "family": "Northeast Caucasian",
        "name": "Avaric",
        "nativeName": "авар мацӀ, магӀарул мацӀ"
    }, {
        "iso639_1": "ay",
        "iso639_2": "aym",
        "family": "Aymaran",
        "name": "Aymara",
        "nativeName": "aymar aru"
    }, {
        "iso639_1": "az",
        "iso639_2": "aze",
        "family": "Turkic",
        "name": "Azerbaijani",
        "nativeName": "azərbaycan dili"
    }, {
        "iso639_1": "ba",
        "iso639_2": "bak",
        "family": "Turkic",
        "name": "Bashkir",
        "nativeName": "башҡорт теле"
    }, {
        "iso639_1": "be",
        "iso639_2": "bel",
        "family": "Indo-European",
        "name": "Belarusian",
        "nativeName": "беларуская мова"
    }, {
        "iso639_1": "bg",
        "iso639_2": "bul",
        "family": "Indo-European",
        "name": "Bulgarian",
        "nativeName": "български език"
    }, {
        "iso639_1": "bh",
        "iso639_2": "bih",
        "family": "Indo-European",
        "name": "Bihari",
        "nativeName": "भोजपुरी"
    }, {
        "iso639_1": "bi",
        "iso639_2": "bis",
        "family": "Creole",
        "name": "Bislama",
        "nativeName": "Bislama"
    }, {
        "iso639_1": "bm",
        "iso639_2": "bam",
        "family": "Niger–Congo",
        "name": "Bambara",
        "nativeName": "bamanankan"
    }, {
        "iso639_1": "bn",
        "iso639_2": "ben",
        "family": "Indo-European",
        "name": "Bengali, Bangla",
        "nativeName": "বাংলা"
    }, {
        "iso639_1": "bo",
        "iso639_2": "bod",
        "family": "Sino-Tibetan",
        "name": "Tibetan Standard, Tibetan, Central",
        "nativeName": "བོད་ཡིག"
    }, {
        "iso639_1": "br",
        "iso639_2": "bre",
        "family": "Indo-European",
        "name": "Breton",
        "nativeName": "brezhoneg"
    }, {
        "iso639_1": "bs",
        "iso639_2": "bos",
        "family": "Indo-European",
        "name": "Bosnian",
        "nativeName": "bosanski jezik"
    }, {
        "iso639_1": "ca",
        "iso639_2": "cat",
        "family": "Indo-European",
        "name": "Catalan",
        "nativeName": "català"
    }, {
        "iso639_1": "ce",
        "iso639_2": "che",
        "family": "Northeast Caucasian",
        "name": "Chechen",
        "nativeName": "нохчийн мотт"
    }, {
        "iso639_1": "ch",
        "iso639_2": "cha",
        "family": "Austronesian",
        "name": "Chamorro",
        "nativeName": "Chamoru"
    }, {
        "iso639_1": "co",
        "iso639_2": "cos",
        "family": "Indo-European",
        "name": "Corsican",
        "nativeName": "corsu, lingua corsa"
    }, {
        "iso639_1": "cr",
        "iso639_2": "cre",
        "family": "Algonquian",
        "name": "Cree",
        "nativeName": "ᓀᐦᐃᔭᐍᐏᐣ"
    }, {
        "iso639_1": "cs",
        "iso639_2": "ces",
        "family": "Indo-European",
        "name": "Czech",
        "nativeName": "čeština, český jazyk"
    }, {
        "iso639_1": "cu",
        "iso639_2": "chu",
        "family": "Indo-European",
        "name": "Old Church Slavonic, Church Slavonic, Old Bulgarian",
        "nativeName": "ѩзыкъ словѣньскъ"
    }, {
        "iso639_1": "cv",
        "iso639_2": "chv",
        "family": "Turkic",
        "name": "Chuvash",
        "nativeName": "чӑваш чӗлхи"
    }, {
        "iso639_1": "cy",
        "iso639_2": "cym",
        "family": "Indo-European",
        "name": "Welsh",
        "nativeName": "Cymraeg"
    }, {
        "iso639_1": "da",
        "iso639_2": "dan",
        "family": "Indo-European",
        "name": "Danish",
        "nativeName": "dansk"
    }, {
        "iso639_1": "de",
        "iso639_2": "deu",
        "family": "Indo-European",
        "name": "German",
        "nativeName": "Deutsch"
    }, {
        "iso639_1": "dv",
        "iso639_2": "div",
        "family": "Indo-European",
        "name": "Divehi, Dhivehi, Maldivian",
        "nativeName": "ދިވެހި"
    }, {
        "iso639_1": "dz",
        "iso639_2": "dzo",
        "family": "Sino-Tibetan",
        "name": "Dzongkha",
        "nativeName": "རྫོང་ཁ"
    }, {
        "iso639_1": "ee",
        "iso639_2": "ewe",
        "family": "Niger–Congo",
        "name": "Ewe",
        "nativeName": "Eʋegbe"
    }, {
        "iso639_1": "el",
        "iso639_2": "ell",
        "family": "Indo-European",
        "name": "Greek",
        "nativeName": "ελληνικά"
    }, {
        "iso639_1": "en",
        "iso639_2": "eng",
        "family": "Indo-European",
        "name": "English",
        "nativeName": "English"
    }, {
        "iso639_1": "eo",
        "iso639_2": "epo",
        "family": "Constructed",
        "name": "Esperanto",
        "nativeName": "Esperanto"
    }, {
        "iso639_1": "es",
        "iso639_2": "spa",
        "family": "Indo-European",
        "name": "Spanish",
        "nativeName": "Español"
    }, {
        "iso639_1": "et",
        "iso639_2": "est",
        "family": "Uralic",
        "name": "Estonian",
        "nativeName": "eesti, eesti keel"
    }, {
        "iso639_1": "eu",
        "iso639_2": "eus",
        "family": "Language isolate",
        "name": "Basque",
        "nativeName": "euskara, euskera"
    }, {
        "iso639_1": "fa",
        "iso639_2": "fas",
        "family": "Indo-European",
        "name": "Persian (Farsi)",
        "nativeName": "فارسی"
    }, {
        "iso639_1": "ff",
        "iso639_2": "ful",
        "family": "Niger–Congo",
        "name": "Fula, Fulah, Pulaar, Pular",
        "nativeName": "Fulfulde, Pulaar, Pular"
    }, {
        "iso639_1": "fi",
        "iso639_2": "fin",
        "family": "Uralic",
        "name": "Finnish",
        "nativeName": "suomi, suomen kieli"
    }, {
        "iso639_1": "fj",
        "iso639_2": "fij",
        "family": "Austronesian",
        "name": "Fijian",
        "nativeName": "vosa Vakaviti"
    }, {
        "iso639_1": "fo",
        "iso639_2": "fao",
        "family": "Indo-European",
        "name": "Faroese",
        "nativeName": "føroyskt"
    }, {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "family": "Indo-European",
        "name": "French",
        "nativeName": "français, langue française"
    }, {
        "iso639_1": "fy",
        "iso639_2": "fry",
        "family": "Indo-European",
        "name": "Western Frisian",
        "nativeName": "Frysk"
    }, {
        "iso639_1": "ga",
        "iso639_2": "gle",
        "family": "Indo-European",
        "name": "Irish",
        "nativeName": "Gaeilge"
    }, {
        "iso639_1": "gd",
        "iso639_2": "gla",
        "family": "Indo-European",
        "name": "Scottish Gaelic, Gaelic",
        "nativeName": "Gàidhlig"
    }, {
        "iso639_1": "gl",
        "iso639_2": "glg",
        "family": "Indo-European",
        "name": "Galician",
        "nativeName": "galego"
    }, {
        "iso639_1": "gn",
        "iso639_2": "grn",
        "family": "Tupian",
        "name": "Guaraní",
        "nativeName": "Avañe'ẽ"
    }, {
        "iso639_1": "gu",
        "iso639_2": "guj",
        "family": "Indo-European",
        "name": "Gujarati",
        "nativeName": "ગુજરાતી"
    }, {
        "iso639_1": "gv",
        "iso639_2": "glv",
        "family": "Indo-European",
        "name": "Manx",
        "nativeName": "Gaelg, Gailck"
    }, {
        "iso639_1": "ha",
        "iso639_2": "hau",
        "family": "Afro-Asiatic",
        "name": "Hausa",
        "nativeName": "(Hausa) هَوُسَ"
    }, {
        "iso639_1": "he",
        "iso639_2": "heb",
        "family": "Afro-Asiatic",
        "name": "Hebrew (modern)",
        "nativeName": "עברית"
    }, {
        "iso639_1": "hi",
        "iso639_2": "hin",
        "family": "Indo-European",
        "name": "Hindi",
        "nativeName": "हिन्दी, हिंदी"
    }, {
        "iso639_1": "ho",
        "iso639_2": "hmo",
        "family": "Austronesian",
        "name": "Hiri Motu",
        "nativeName": "Hiri Motu"
    }, {
        "iso639_1": "hr",
        "iso639_2": "hrv",
        "family": "Indo-European",
        "name": "Croatian",
        "nativeName": "hrvatski jezik"
    }, {
        "iso639_1": "ht",
        "iso639_2": "hat",
        "family": "Creole",
        "name": "Haitian, Haitian Creole",
        "nativeName": "Kreyòl ayisyen"
    }, {
        "iso639_1": "hu",
        "iso639_2": "hun",
        "family": "Uralic",
        "name": "Hungarian",
        "nativeName": "magyar"
    }, {
        "iso639_1": "hy",
        "iso639_2": "hye",
        "family": "Indo-European",
        "name": "Armenian",
        "nativeName": "Հայերեն"
    }, {
        "iso639_1": "hz",
        "iso639_2": "her",
        "family": "Niger–Congo",
        "name": "Herero",
        "nativeName": "Otjiherero"
    }, {
        "iso639_1": "ia",
        "iso639_2": "ina",
        "family": "Constructed",
        "name": "Interlingua",
        "nativeName": "Interlingua"
    }, {
        "iso639_1": "id",
        "iso639_2": "ind",
        "family": "Austronesian",
        "name": "Indonesian",
        "nativeName": "Bahasa Indonesia"
    }, {
        "iso639_1": "ie",
        "iso639_2": "ile",
        "family": "Constructed",
        "name": "Interlingue",
        "nativeName": "Originally called Occidental; then Interlingue after WWII"
    }, {
        "iso639_1": "ig",
        "iso639_2": "ibo",
        "family": "Niger–Congo",
        "name": "Igbo",
        "nativeName": "Asụsụ Igbo"
    }, {
        "iso639_1": "ii",
        "iso639_2": "iii",
        "family": "Sino-Tibetan",
        "name": "Nuosu",
        "nativeName": "ꆈꌠ꒿ Nuosuhxop"
    }, {
        "iso639_1": "ik",
        "iso639_2": "ipk",
        "family": "Eskimo–Aleut",
        "name": "Inupiaq",
        "nativeName": "Iñupiaq, Iñupiatun"
    }, {
        "iso639_1": "io",
        "iso639_2": "ido",
        "family": "Constructed",
        "name": "Ido",
        "nativeName": "Ido"
    }, {
        "iso639_1": "is",
        "iso639_2": "isl",
        "family": "Indo-European",
        "name": "Icelandic",
        "nativeName": "Íslenska"
    }, {
        "iso639_1": "it",
        "iso639_2": "ita",
        "family": "Indo-European",
        "name": "Italian",
        "nativeName": "Italiano"
    }, {
        "iso639_1": "iu",
        "iso639_2": "iku",
        "family": "Eskimo–Aleut",
        "name": "Inuktitut",
        "nativeName": "ᐃᓄᒃᑎᑐᑦ"
    }, {
        "iso639_1": "ja",
        "iso639_2": "jpn",
        "family": "Japonic",
        "name": "Japanese",
        "nativeName": "日本語 (にほんご)"
    }, {
        "iso639_1": "jv",
        "iso639_2": "jav",
        "family": "Austronesian",
        "name": "Javanese",
        "nativeName": "ꦧꦱꦗꦮ, Basa Jawa"
    }, {
        "iso639_1": "ka",
        "iso639_2": "kat",
        "family": "South Caucasian",
        "name": "Georgian",
        "nativeName": "ქართული"
    }, {
        "iso639_1": "kg",
        "iso639_2": "kon",
        "family": "Niger–Congo",
        "name": "Kongo",
        "nativeName": "Kikongo"
    }, {
        "iso639_1": "ki",
        "iso639_2": "kik",
        "family": "Niger–Congo",
        "name": "Kikuyu, Gikuyu",
        "nativeName": "Gĩkũyũ"
    }, {
        "iso639_1": "kj",
        "iso639_2": "kua",
        "family": "Niger–Congo",
        "name": "Kwanyama, Kuanyama",
        "nativeName": "Kuanyama"
    }, {
        "iso639_1": "kk",
        "iso639_2": "kaz",
        "family": "Turkic",
        "name": "Kazakh",
        "nativeName": "қазақ тілі"
    }, {
        "iso639_1": "kl",
        "iso639_2": "kal",
        "family": "Eskimo–Aleut",
        "name": "Kalaallisut, Greenlandic",
        "nativeName": "kalaallisut, kalaallit oqaasii"
    }, {
        "iso639_1": "km",
        "iso639_2": "khm",
        "family": "Austroasiatic",
        "name": "Khmer",
        "nativeName": "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ"
    }, {
        "iso639_1": "kn",
        "iso639_2": "kan",
        "family": "Dravidian",
        "name": "Kannada",
        "nativeName": "ಕನ್ನಡ"
    }, {
        "iso639_1": "ko",
        "iso639_2": "kor",
        "family": "Koreanic",
        "name": "Korean",
        "nativeName": "한국어"
    }, {
        "iso639_1": "kr",
        "iso639_2": "kau",
        "family": "Nilo-Saharan",
        "name": "Kanuri",
        "nativeName": "Kanuri"
    }, {
        "iso639_1": "ks",
        "iso639_2": "kas",
        "family": "Indo-European",
        "name": "Kashmiri",
        "nativeName": "कश्मीरी, كشميري‎"
    }, {
        "iso639_1": "ku",
        "iso639_2": "kur",
        "family": "Indo-European",
        "name": "Kurdish",
        "nativeName": "Kurdî, كوردی‎"
    }, {
        "iso639_1": "kv",
        "iso639_2": "kom",
        "family": "Uralic",
        "name": "Komi",
        "nativeName": "коми кыв"
    }, {
        "iso639_1": "kw",
        "iso639_2": "cor",
        "family": "Indo-European",
        "name": "Cornish",
        "nativeName": "Kernewek"
    }, {
        "iso639_1": "ky",
        "iso639_2": "kir",
        "family": "Turkic",
        "name": "Kyrgyz",
        "nativeName": "Кыргызча, Кыргыз тили"
    }, {
        "iso639_1": "la",
        "iso639_2": "lat",
        "family": "Indo-European",
        "name": "Latin",
        "nativeName": "latine, lingua latina"
    }, {
        "iso639_1": "lb",
        "iso639_2": "ltz",
        "family": "Indo-European",
        "name": "Luxembourgish, Letzeburgesch",
        "nativeName": "Lëtzebuergesch"
    }, {
        "iso639_1": "lg",
        "iso639_2": "lug",
        "family": "Niger–Congo",
        "name": "Ganda",
        "nativeName": "Luganda"
    }, {
        "iso639_1": "li",
        "iso639_2": "lim",
        "family": "Indo-European",
        "name": "Limburgish, Limburgan, Limburger",
        "nativeName": "Limburgs"
    }, {
        "iso639_1": "ln",
        "iso639_2": "lin",
        "family": "Niger–Congo",
        "name": "Lingala",
        "nativeName": "Lingála"
    }, {
        "iso639_1": "lo",
        "iso639_2": "lao",
        "family": "Tai–Kadai",
        "name": "Lao",
        "nativeName": "ພາສາລາວ"
    }, {
        "iso639_1": "lt",
        "iso639_2": "lit",
        "family": "Indo-European",
        "name": "Lithuanian",
        "nativeName": "lietuvių kalba"
    }, {
        "iso639_1": "lu",
        "iso639_2": "lub",
        "family": "Niger–Congo",
        "name": "Luba-Katanga",
        "nativeName": "Tshiluba"
    }, {
        "iso639_1": "lv",
        "iso639_2": "lav",
        "family": "Indo-European",
        "name": "Latvian",
        "nativeName": "latviešu valoda"
    }, {
        "iso639_1": "mg",
        "iso639_2": "mlg",
        "family": "Austronesian",
        "name": "Malagasy",
        "nativeName": "fiteny malagasy"
    }, {
        "iso639_1": "mh",
        "iso639_2": "mah",
        "family": "Austronesian",
        "name": "Marshallese",
        "nativeName": "Kajin M̧ajeļ"
    }, {
        "iso639_1": "mi",
        "iso639_2": "mri",
        "family": "Austronesian",
        "name": "Māori",
        "nativeName": "te reo Māori"
    }, {
        "iso639_1": "mk",
        "iso639_2": "mkd",
        "family": "Indo-European",
        "name": "Macedonian",
        "nativeName": "македонски јазик"
    }, {
        "iso639_1": "ml",
        "iso639_2": "mal",
        "family": "Dravidian",
        "name": "Malayalam",
        "nativeName": "മലയാളം"
    }, {
        "iso639_1": "mn",
        "iso639_2": "mon",
        "family": "Mongolic",
        "name": "Mongolian",
        "nativeName": "Монгол хэл"
    }, {
        "iso639_1": "mr",
        "iso639_2": "mar",
        "family": "Indo-European",
        "name": "Marathi (Marāṭhī)",
        "nativeName": "मराठी"
    }, {
        "iso639_1": "ms",
        "iso639_2": "msa",
        "family": "Austronesian",
        "name": "Malay",
        "nativeName": "bahasa Melayu, بهاس ملايو‎"
    }, {
        "iso639_1": "mt",
        "iso639_2": "mlt",
        "family": "Afro-Asiatic",
        "name": "Maltese",
        "nativeName": "Malti"
    }, {
        "iso639_1": "my",
        "iso639_2": "mya",
        "family": "Sino-Tibetan",
        "name": "Burmese",
        "nativeName": "ဗမာစာ"
    }, {
        "iso639_1": "na",
        "iso639_2": "nau",
        "family": "Austronesian",
        "name": "Nauruan",
        "nativeName": "Dorerin Naoero"
    }, {
        "iso639_1": "nb",
        "iso639_2": "nob",
        "family": "Indo-European",
        "name": "Norwegian Bokmål",
        "nativeName": "Norsk bokmål"
    }, {
        "iso639_1": "nd",
        "iso639_2": "nde",
        "family": "Niger–Congo",
        "name": "Northern Ndebele",
        "nativeName": "isiNdebele"
    }, {
        "iso639_1": "ne",
        "iso639_2": "nep",
        "family": "Indo-European",
        "name": "Nepali",
        "nativeName": "नेपाली"
    }, {
        "iso639_1": "ng",
        "iso639_2": "ndo",
        "family": "Niger–Congo",
        "name": "Ndonga",
        "nativeName": "Owambo"
    }, {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "family": "Indo-European",
        "name": "Dutch",
        "nativeName": "Nederlands, Vlaams"
    }, {
        "iso639_1": "nn",
        "iso639_2": "nno",
        "family": "Indo-European",
        "name": "Norwegian Nynorsk",
        "nativeName": "Norsk nynorsk"
    }, {
        "iso639_1": "no",
        "iso639_2": "nor",
        "family": "Indo-European",
        "name": "Norwegian",
        "nativeName": "Norsk"
    }, {
        "iso639_1": "nr",
        "iso639_2": "nbl",
        "family": "Niger–Congo",
        "name": "Southern Ndebele",
        "nativeName": "isiNdebele"
    }, {
        "iso639_1": "nv",
        "iso639_2": "nav",
        "family": "Dené–Yeniseian",
        "name": "Navajo, Navaho",
        "nativeName": "Diné bizaad"
    }, {
        "iso639_1": "ny",
        "iso639_2": "nya",
        "family": "Niger–Congo",
        "name": "Chichewa, Chewa, Nyanja",
        "nativeName": "chiCheŵa, chinyanja"
    }, {
        "iso639_1": "oc",
        "iso639_2": "oci",
        "family": "Indo-European",
        "name": "Occitan",
        "nativeName": "occitan, lenga d'òc"
    }, {
        "iso639_1": "oj",
        "iso639_2": "oji",
        "family": "Algonquian",
        "name": "Ojibwe, Ojibwa",
        "nativeName": "ᐊᓂᔑᓈᐯᒧᐎᓐ"
    }, {
        "iso639_1": "om",
        "iso639_2": "orm",
        "family": "Afro-Asiatic",
        "name": "Oromo",
        "nativeName": "Afaan Oromoo"
    }, {
        "iso639_1": "or",
        "iso639_2": "ori",
        "family": "Indo-European",
        "name": "Oriya",
        "nativeName": "ଓଡ଼ିଆ"
    }, {
        "iso639_1": "os",
        "iso639_2": "oss",
        "family": "Indo-European",
        "name": "Ossetian, Ossetic",
        "nativeName": "ирон æвзаг"
    }, {
        "iso639_1": "pa",
        "iso639_2": "pan",
        "family": "Indo-European",
        "name": "(Eastern) Punjabi",
        "nativeName": "ਪੰਜਾਬੀ"
    }, {
        "iso639_1": "pi",
        "iso639_2": "pli",
        "family": "Indo-European",
        "name": "Pāli",
        "nativeName": "पाऴि"
    }, {
        "iso639_1": "pl",
        "iso639_2": "pol",
        "family": "Indo-European",
        "name": "Polish",
        "nativeName": "język polski, polszczyzna"
    }, {
        "iso639_1": "ps",
        "iso639_2": "pus",
        "family": "Indo-European",
        "name": "Pashto, Pushto",
        "nativeName": "پښتو"
    }, {
        "iso639_1": "pt",
        "iso639_2": "por",
        "family": "Indo-European",
        "name": "Portuguese",
        "nativeName": "Português"
    }, {
        "iso639_1": "qu",
        "iso639_2": "que",
        "family": "Quechuan",
        "name": "Quechua",
        "nativeName": "Runa Simi, Kichwa"
    }, {
        "iso639_1": "rm",
        "iso639_2": "roh",
        "family": "Indo-European",
        "name": "Romansh",
        "nativeName": "rumantsch grischun"
    }, {
        "iso639_1": "rn",
        "iso639_2": "run",
        "family": "Niger–Congo",
        "name": "Kirundi",
        "nativeName": "Ikirundi"
    }, {
        "iso639_1": "ro",
        "iso639_2": "ron",
        "family": "Indo-European",
        "name": "Romanian",
        "nativeName": "Română"
    }, {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "family": "Indo-European",
        "name": "Russian",
        "nativeName": "Русский"
    }, {
        "iso639_1": "rw",
        "iso639_2": "kin",
        "family": "Niger–Congo",
        "name": "Kinyarwanda",
        "nativeName": "Ikinyarwanda"
    }, {
        "iso639_1": "sa",
        "iso639_2": "san",
        "family": "Indo-European",
        "name": "Sanskrit (Saṁskṛta)",
        "nativeName": "संस्कृतम्"
    }, {
        "iso639_1": "sc",
        "iso639_2": "srd",
        "family": "Indo-European",
        "name": "Sardinian",
        "nativeName": "sardu"
    }, {
        "iso639_1": "sd",
        "iso639_2": "snd",
        "family": "Indo-European",
        "name": "Sindhi",
        "nativeName": "सिन्धी, سنڌي، سندھی‎"
    }, {
        "iso639_1": "se",
        "iso639_2": "sme",
        "family": "Uralic",
        "name": "Northern Sami",
        "nativeName": "Davvisámegiella"
    }, {
        "iso639_1": "sg",
        "iso639_2": "sag",
        "family": "Creole",
        "name": "Sango",
        "nativeName": "yângâ tî sängö"
    }, {
        "iso639_1": "si",
        "iso639_2": "sin",
        "family": "Indo-European",
        "name": "Sinhalese, Sinhala",
        "nativeName": "සිංහල"
    }, {
        "iso639_1": "sk",
        "iso639_2": "slk",
        "family": "Indo-European",
        "name": "Slovak",
        "nativeName": "slovenčina, slovenský jazyk"
    }, {
        "iso639_1": "sl",
        "iso639_2": "slv",
        "family": "Indo-European",
        "name": "Slovene",
        "nativeName": "slovenski jezik, slovenščina"
    }, {
        "iso639_1": "sm",
        "iso639_2": "smo",
        "family": "Austronesian",
        "name": "Samoan",
        "nativeName": "gagana fa'a Samoa"
    }, {
        "iso639_1": "sn",
        "iso639_2": "sna",
        "family": "Niger–Congo",
        "name": "Shona",
        "nativeName": "chiShona"
    }, {
        "iso639_1": "so",
        "iso639_2": "som",
        "family": "Afro-Asiatic",
        "name": "Somali",
        "nativeName": "Soomaaliga, af Soomaali"
    }, {
        "iso639_1": "sq",
        "iso639_2": "sqi",
        "family": "Indo-European",
        "name": "Albanian",
        "nativeName": "Shqip"
    }, {
        "iso639_1": "sr",
        "iso639_2": "srp",
        "family": "Indo-European",
        "name": "Serbian",
        "nativeName": "српски језик"
    }, {
        "iso639_1": "ss",
        "iso639_2": "ssw",
        "family": "Niger–Congo",
        "name": "Swati",
        "nativeName": "SiSwati"
    }, {
        "iso639_1": "st",
        "iso639_2": "sot",
        "family": "Niger–Congo",
        "name": "Southern Sotho",
        "nativeName": "Sesotho"
    }, {
        "iso639_1": "su",
        "iso639_2": "sun",
        "family": "Austronesian",
        "name": "Sundanese",
        "nativeName": "Basa Sunda"
    }, {
        "iso639_1": "sv",
        "iso639_2": "swe",
        "family": "Indo-European",
        "name": "Swedish",
        "nativeName": "svenska"
    }, {
        "iso639_1": "sw",
        "iso639_2": "swa",
        "family": "Niger–Congo",
        "name": "Swahili",
        "nativeName": "Kiswahili"
    }, {
        "iso639_1": "ta",
        "iso639_2": "tam",
        "family": "Dravidian",
        "name": "Tamil",
        "nativeName": "தமிழ்"
    }, {
        "iso639_1": "te",
        "iso639_2": "tel",
        "family": "Dravidian",
        "name": "Telugu",
        "nativeName": "తెలుగు"
    }, {
        "iso639_1": "tg",
        "iso639_2": "tgk",
        "family": "Indo-European",
        "name": "Tajik",
        "nativeName": "тоҷикӣ, toçikī, تاجیکی‎"
    }, {
        "iso639_1": "th",
        "iso639_2": "tha",
        "family": "Tai–Kadai",
        "name": "Thai",
        "nativeName": "ไทย"
    }, {
        "iso639_1": "ti",
        "iso639_2": "tir",
        "family": "Afro-Asiatic",
        "name": "Tigrinya",
        "nativeName": "ትግርኛ"
    }, {
        "iso639_1": "tk",
        "iso639_2": "tuk",
        "family": "Turkic",
        "name": "Turkmen",
        "nativeName": "Türkmen, Түркмен"
    }, {
        "iso639_1": "tl",
        "iso639_2": "tgl",
        "family": "Austronesian",
        "name": "Tagalog",
        "nativeName": "Wikang Tagalog"
    }, {
        "iso639_1": "tn",
        "iso639_2": "tsn",
        "family": "Niger–Congo",
        "name": "Tswana",
        "nativeName": "Setswana"
    }, {
        "iso639_1": "to",
        "iso639_2": "ton",
        "family": "Austronesian",
        "name": "Tonga (Tonga Islands)",
        "nativeName": "faka Tonga"
    }, {
        "iso639_1": "tr",
        "iso639_2": "tur",
        "family": "Turkic",
        "name": "Turkish",
        "nativeName": "Türkçe"
    }, {
        "iso639_1": "ts",
        "iso639_2": "tso",
        "family": "Niger–Congo",
        "name": "Tsonga",
        "nativeName": "Xitsonga"
    }, {
        "iso639_1": "tt",
        "iso639_2": "tat",
        "family": "Turkic",
        "name": "Tatar",
        "nativeName": "татар теле, tatar tele"
    }, {
        "iso639_1": "tw",
        "iso639_2": "twi",
        "family": "Niger–Congo",
        "name": "Twi",
        "nativeName": "Twi"
    }, {
        "iso639_1": "ty",
        "iso639_2": "tah",
        "family": "Austronesian",
        "name": "Tahitian",
        "nativeName": "Reo Tahiti"
    }, {
        "iso639_1": "ug",
        "iso639_2": "uig",
        "family": "Turkic",
        "name": "Uyghur",
        "nativeName": "ئۇيغۇرچە‎, Uyghurche"
    }, {
        "iso639_1": "uk",
        "iso639_2": "ukr",
        "family": "Indo-European",
        "name": "Ukrainian",
        "nativeName": "Українська"
    }, {
        "iso639_1": "ur",
        "iso639_2": "urd",
        "family": "Indo-European",
        "name": "Urdu",
        "nativeName": "اردو"
    }, {
        "iso639_1": "uz",
        "iso639_2": "uzb",
        "family": "Turkic",
        "name": "Uzbek",
        "nativeName": "Oʻzbek, Ўзбек, أۇزبېك‎"
    }, {
        "iso639_1": "ve",
        "iso639_2": "ven",
        "family": "Niger–Congo",
        "name": "Venda",
        "nativeName": "Tshivenḓa"
    }, {
        "iso639_1": "vi",
        "iso639_2": "vie",
        "family": "Austroasiatic",
        "name": "Vietnamese",
        "nativeName": "Tiếng Việt"
    }, {
        "iso639_1": "vo",
        "iso639_2": "vol",
        "family": "Constructed",
        "name": "Volapük",
        "nativeName": "Volapük"
    }, {
        "iso639_1": "wa",
        "iso639_2": "wln",
        "family": "Indo-European",
        "name": "Walloon",
        "nativeName": "walon"
    }, {
        "iso639_1": "wo",
        "iso639_2": "wol",
        "family": "Niger–Congo",
        "name": "Wolof",
        "nativeName": "Wollof"
    }, {
        "iso639_1": "xh",
        "iso639_2": "xho",
        "family": "Niger–Congo",
        "name": "Xhosa",
        "nativeName": "isiXhosa"
    }, {
        "iso639_1": "yi",
        "iso639_2": "yid",
        "family": "Indo-European",
        "name": "Yiddish",
        "nativeName": "ייִדיש"
    }, {
        "iso639_1": "yo",
        "iso639_2": "yor",
        "family": "Niger–Congo",
        "name": "Yoruba",
        "nativeName": "Yorùbá"
    }, {
        "iso639_1": "za",
        "iso639_2": "zha",
        "family": "Tai–Kadai",
        "name": "Zhuang, Chuang",
        "nativeName": "Saɯ cueŋƅ, Saw cuengh"
    }, {
        "iso639_1": "zh",
        "iso639_2": "zho",
        "family": "Sino-Tibetan",
        "name": "Chinese",
        "nativeName": "中文 (Zhōngwén), 汉语, 漢語"
    }, {
        "iso639_1": "zu",
        "iso639_2": "zul",
        "family": "Niger–Congo",
        "name": "Zulu",
        "nativeName": "isiZulu"
    }
]