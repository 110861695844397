import { HostBinding, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { RequestService } from '../requestService/request.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: any;
  private company: any;
  getLoggedInStatus = new Subject();
  getUpdateStatus = new Subject();

  @HostBinding('class') className = '';
  html: HTMLCollectionOf<HTMLElement>[0] = document.getElementsByTagName('html')[0];

  constructor(private router: Router, private requestService: RequestService) { }

  /* *** User *** */
  setUser(data: any) {
    data.avatar = data.firstName.slice(0, 1) + data.lastName.slice(0, 1);
    this.user = data;
  };

  getUser() {
    return this.user;
  };

  /** Update user */
  updateUser(newUser: any) {
    this.user = newUser;
  }

  //Reset User
  resetUser() {
    this.user = null as any;
    this.router.navigate(['login']);
  };

  /** Set company
   * @param data: Company type
  */
  setCompany(data: any): void {
    this.company = data;
  };

  /** Return user company
   * @returns company: Company type
  */
  getCompany(): any {
    return this.company;
  };

  //Reset Comapny
  resetCompany() {
    this.company = null;
  };

  /** Check if the user is logged in or not
   * @returns {Boolean} */
  isLoggedIn(): boolean {
    return this.user ? true : false;
  }

  /** Check if user is admin or not
   * @returns {Boolean} */
  isAdmin(): boolean {
    return this.user.type == 1 ? true : false;
  }

  //Get User Info
  getUserInfo() {
    let req = {
      url: "auth/info"
    };
    return this.requestService.mainGetRequest(req)
  }

  /** Logout user */
  logout() {
    let req = {
      url: "auth/logout"
    };
    this.requestService.mainGetRequest(req).then(resp => {
      this.resetCompany();
      this.resetUser();
      this.getLoggedInStatus.next(false);
      location.reload();
    })
  }
  checkStatus(resp: any) {
    //Already Logged In User
    if (resp.success == true) {
      this.setUser(resp.data.user);
      this.setCompany(resp.data.company);
    } else {
    }
  }

  changeTheme(theme: string): void {
    this.html.dataset['theme'] = `theme-${theme}`;
    this.changeMode(theme == 'light' ? '': 'theme-dark');
  }

  // Select Mode (theme) between Dark - Light 
  changeMode(mode: string): void {
    //set ClassName as 'theme-dark' or ''
    this.className = mode;
  
    if(this.className){
      // document.getElementsByClassName('content-wrapper')[0].classList.add(this.className);
      document.body.classList.add(this.className);
      // document.getElementsByClassName('top-bar mat-toolbar-single-row')[0].classList.add(this.className);
    }else{
      const darkMode = 'theme-dark';
      // document.getElementsByClassName('content-wrapper')[0].classList.remove(darkMode);
      document.body.classList.remove(darkMode);
      // document.getElementsByClassName('top-bar mat-toolbar-single-row')[0].classList.remove(darkMode);
    }
  }
}