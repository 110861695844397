import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateDrawComponent } from './components/create-draw/create-draw.component';
import { CreatePollComponent } from './components/create-poll/create-poll.component';
import { DrawResultComponent } from './components/draw-result/draw-result.component';
import { DrawsComponent } from './components/draws/draws.component';
import { PollChartsComponent } from './components/poll-charts/poll-charts.component';
import { PollsComponent } from './components/polls/polls.component';

const routes: Routes = [
  /* { path: 'dashboard', component: RevenueDashboardComponent } */
  { path: '', component: PollsComponent },
  { path: 'polls', component: PollsComponent },
  { path: 'create-poll', component: CreatePollComponent },
  { path: 'edit-poll/:id', component: CreatePollComponent },
  { path: 'create-dublicate/:id', component: CreatePollComponent },
  { path: 'poll-chart/:id', component: PollChartsComponent },
  { path: 'draws', component: DrawsComponent },
  { path: 'create-draw', component: CreateDrawComponent },
  { path: 'draw-result/:id', component: DrawResultComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
