<div class="card">
    <div class="card-header h-auto">
        {{"poll_chart_live_feed" | translate}}
    </div>
    <div class="card-body">
        <div class="bar-chart">
            <h3 class="chart-title">{{ config.title}}</h3>
            <table mat-table [dataSource]="data">
                <!-- answer Column -->
                <ng-container matColumnDef="answer">
                    <th mat-header-cell *matHeaderCellDef> Απάντηση </th>
                    <td mat-cell *matCellDef="let element"> {{element.answer}} </td>
                </ng-container>

                <!-- value Column -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Αποτέλεσμα </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                </ng-container>

                <!-- percentage Column -->
                <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef> Ποσοστό </th>
                    <td mat-cell *matCellDef="let element"> {{element.percentage}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>