import { Component, ContentChildren, ElementRef, OnInit, QueryList } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { languages } from '../../interface/poll';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { PollService } from 'src/app/services/pollService/poll.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-create-poll',
  templateUrl: './create-poll.component.html',
  styleUrls: ['./create-poll.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class CreatePollComponent implements OnInit {
  today: Date = new Date();
  fg!: UntypedFormGroup
  pollId!: string
  editPoll: any = {}
  statusValue!: number
  disableButtons: boolean = false
  publishPoll: boolean = true
  contentIdsValue!: string
  selectedTab: number = 0
  forTheFuture: boolean = false;
  deleteAnswerId: Array<Number> = [];
  languages: languages[] = [
    { name: 'English', id: 'en' },
    { name: 'Ελληνικά', id: 'el' }
  ]

  @ContentChildren(NgModel, { descendants: true, read: ElementRef })
  inputFields!: QueryList<ElementRef>

  hours: string[] = ['00','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12','13','14','15','16','17','18','19','20','21','22','23'];
  minutes: string[] = ['00', '05', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  currentHour: string;
  currentMinutes: string;
  currentDate: UntypedFormControl;
  scheduledDateTime!: Date;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private pollService: PollService
  ) {
    this.fg = this.fb.group({
      title: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl(0, Validators.required),
      duration: new UntypedFormControl(0, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      contentIds: new UntypedFormControl([], Validators.required),
      translations: this.buildTranslations()
    });

    this.currentHour = (new Date().getHours()+1).toString();
    this.currentMinutes = '00';
    this.currentDate = new UntypedFormControl(new Date());
    this.scheduledDateTime = new Date(moment(this.currentDate.value).set({ "hour": Number(this.currentHour), "minutes": Number(this.currentMinutes),"second": 0 }).toLocaleString());

  }

  ngOnInit(): void {
    // Check if page contains edit-poll or create-dublicate
    if (this.router.url.includes('/edit-poll/') || this.router.url.includes('/create-dublicate/')) {
      this.pollId = String(this.route.snapshot.paramMap.get('id'))
      this.onGetPoll()
    } else {
      this.addAnswer()
    }
  }
  // Build Poll Translations Form
  buildTranslations(): UntypedFormArray {
    const groups = this.languages.map((l: any) =>
      this.fb.group({
        langId: l.id,
        question: new UntypedFormControl('', [Validators.required, Validators.maxLength(75)]),
        answers: new UntypedFormArray([]),
      })
    );

    return this.fb.array(groups);
  }

  // Get translations controls
  get translations(): UntypedFormArray {
    return this.fg.get('translations') as UntypedFormArray;
  }

  // get Answers controls
  formAnswer(formIndex: number): UntypedFormArray {
    return this.translations.at(formIndex).get('answers') as UntypedFormArray;
  }

  // get Answers from getPoll
  populateAnswers(index: number, value: any, id: any) {
    this.formAnswer(index).push(
      this.fb.group({
        answer: value,
        id: id
      })
    );
  }
  // Add new answer
  addAnswer(): any {
    this.languages.forEach((lang, i) => {
      this.formAnswer(i).push(this.newAnswer())
    })
  }
  // create new Answer
  newAnswer(): UntypedFormGroup {
    return this.fb.group({
      answer: '',
    });
  }

  // Remove Answer
  removeAnswer(index2: number) {
    /* Pushing the id of the answer to be deleted to an array. */
    this.deleteAnswerId.push(this.formAnswer(0).value[index2].id)
    this.languages.forEach((ans, i) => {
      console.log(ans)
      this.formAnswer(i).removeAt(index2)
    })
    console.log(this.deleteAnswerId);
  }


  // get Questions from form
  getQuestions() {
    return this.translations.value.map((x: any) => {
      return {
        language: x.langId,
        text: x.question
      }
    })
  }

  // get Answers from form
  getAnswers() {
    let array: any = [];
    for (let j = 0; j < this.translations.value[0].answers.length; j++) {
      array.push({
        order: j, translations: [], ...(this.translations.value[0].answers[j].id && { answerId: this.translations.value[0].answers[j].id })
      });
      this.translations.value.map((translation: any) => {
        let langAnswers = this.translations.value.find((trans: any) => trans.langId === translation.langId
        )
        array[j].translations.push({
          language: translation.langId,
          text: langAnswers.answers[j].answer
        })
      })
    }
    return array
  }

  // Get each answer from form
  getAnswerAnswer(object: any, index: number) {
    return {
      languages: object.langId,
      text: object.answers[index].answer
    }
  }

  // Get Poll with Url's id
  onGetPoll() {
    this.pollService.getPoll(this.pollId).then(resp => {
      // edit a default poll
      this.editPoll = resp.data;
      this.setValues(this.editPoll)
      if ((this.editPoll.status == 1 || this.editPoll.status == 2) && !this.router.url.includes('/create-dublicate/')) {
        this.fg.disable();
        this.disableButtons = true;
      }
      if (this.editPoll.status == 2) {
        this.forTheFuture = true;
      }
    });
  }

  // set Values of Form
  setValues(poll: any) {
    this.fg.get('title')?.setValue(poll.title)
    this.fg.get('contentIds')?.setValue(poll.contentIds)
    this.fg.get('status')?.setValue(poll.status)
    this.fg.get('duration')?.setValue(poll.duration)
    this.patchQuestions(poll.pollQuestionTranslations)
    this.patchAnswers(poll.pollAnswers)
    if (this.editPoll.status == 2) {
      // Hours
      this.currentHour = moment(this.editPoll.scheduledDateTime).hour().toLocaleString().length == 1 ? '0' + moment(this.editPoll.scheduledDateTime).hour().toLocaleString()
        : moment(this.editPoll.scheduledDateTime).hour().toLocaleString();
      // Minutes
      this.currentMinutes = moment(this.editPoll.scheduledDateTime).minutes().toLocaleString() == '0' ? '00'
        : moment(this.editPoll.scheduledDateTime).minutes().toLocaleString().length == 1 ? '0' + moment(this.editPoll.scheduledDateTime).minutes().toLocaleString()
          : moment(this.editPoll.scheduledDateTime).minutes().toLocaleString();
      // Date
      this.currentDate = new UntypedFormControl(new Date(moment(this.editPoll.scheduledDateTime).toLocaleString()));
      this.scheduledDateTime = new Date(moment(this.editPoll.scheduledDateTime).toLocaleString());
    }
  }

  /**
   * For each question in the array, find the corresponding language in the form array and set the
   * question value.
   */
  patchQuestions(question: any) {
    for (let j = 0; j < this.translations.value.length; j++) {
      for (const element of question) {
        if (this.translations.value[j].langId == element.language) {
          this.translations.at(j).get('question')?.setValue(element.question)
        }
      }
    }
  }

  /**
    if the language of
   * the current translation is equal to the language of the current pollAnswerTranslation, populate the
   * answers
   */
  patchAnswers(answers: any) {
    for (let j = 0; j < this.translations.value.length; j++) {
      for (const element of answers) {
        element.pollAnswerTranslations.map((x: any) => {
          if (this.translations.value[j].langId == x.language) {
            this.populateAnswers(j, x.answer, element.pollAnswerId)
          }
        })
      }
    }
  }

  /**
   * If the form group has a status control, set the value to Draft, then call the onSubmit function.
   */
  onSaveAsDraft() {
    this.publishPoll = false;
    this.onSubmit()
  }

  onPublishPoll(publish: boolean, id: number) {
    if (publish) {
      this.pollService.publishPoll(id).then( resp => { });
    }
  }

  // Submit Form
  onSubmit(): void {
    if (this.fg.get('contentIds')?.value.length != 0) {
      this.fg.get('contentIds')?.setValue(this.fg.get('contentIds')?.value.toString().split(/[, ]+/).filter(Boolean))
    }

    if (this.fg.valid) {
      this.postPoll()
    } else {
      this.findInvalidTab()
    }
  }

  /**
   * If the form is invalid, find the first invalid control and set the selectedTab to the index of that
   * control.
   */
  findInvalidTab() {
    const invalid = [];
    const controls = this.translations.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
      this.selectedTab = Number(invalid[0])
    }
  }

  // Post Poll Method
  postPoll() {
    let params: any = {
      title: this.fg.get("title")?.value,
      status: this.fg.get("status")?.value,
      contentIds: this.fg.get("contentIds")?.value,
      duration: Number(this.fg.get("duration")?.value),
      translations: this.translations
    }
    // If poll scheduled
    if (this.forTheFuture) {
      params['scheduledDateTime'] = new Date(moment(this.scheduledDateTime).set({ "hour": Number(this.currentHour), "minutes": Number(this.currentMinutes) }).toLocaleString()).toISOString();
      params['status'] = 2;
    }
    if (this.router.url.includes('/edit-poll/')) {
      params["id"] = String(this.pollId)
      this.pollService.editPoll(params).then( resp => {
        this.upload(this.publishPoll, resp.data.pollId)
      });
    } else {
      if (this.router.url.includes('/create-dublicate/')) {
        params.status = this.forTheFuture ? 2 : 0;
      }
      this.pollService.createPoll(params).then( resp => {
        this.upload(this.publishPoll, resp.data.pollId)
      });
    }
  }
  
  upload(publishPoll: any, id: any): void{
    this.onPublishPoll(publishPoll, id);
    this.router.navigateByUrl("polls");
  }

  // Set Scheduled
  changeSchedule(event: any): void {
    this.forTheFuture = event.checked;
  }


  changeDate(event: any): void {
    this.scheduledDateTime = new Date(moment(event.value).set({ "hour": Number(this.currentHour), "minutes": Number(this.currentMinutes) }).toLocaleString());
  }

  changeTime(event: any, type: string): void {
    switch (type) {
      case 'hour':
        this.scheduledDateTime = new Date(moment(this.scheduledDateTime).set({ "hour": Number(event.value) }).toLocaleString());
        break;
      case 'minutes':
        this.scheduledDateTime = new Date(moment(this.scheduledDateTime).set({ "minutes": Number(event.value) }).toLocaleString());
        break;
      default:
        break;
    }
  }
}
